/*===== ACTIVE AND REMOVE MENU =====*/
const navLinksMenu = document.querySelectorAll('.nav__link')
const navLinksBurgerMenu = document.querySelectorAll('.burger-menu_link')

function handleActiveMenu(navLinks) {
  navLinks.forEach((n) => {
    let url = document.URL.replace('index.html', '')
    if (url === n.href) {
      n.classList.add('active')
    }
  })
}

/*===== DROPDOWN =====*/
document.querySelectorAll('.dropdown-button').forEach((n) => {
  n.addEventListener('click', function () {
    console.log('bla')
    this.parentNode.classList.toggle('closed')
  })
})

window.onload = function () {
  handleActiveMenu(navLinksMenu)
  handleActiveMenu(navLinksBurgerMenu)
}
